import * as React from 'react'
import classnames from 'classnames'
import Button, { ButtonType } from 'components/Base/Button'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as styles from './index.module.less'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation]);

interface MatterCardProps {
  reverse?: boolean;
  className?: string;
  title?: string;
  desc?: string | React.ReactNode;
  buttonText?: string;
  buttonType?: string;
  buttonHref?: string;
  target?: string;
  thumb?: string;
  thumbWidth?: number;
  alt?: string;
  style?: any
}

const MatterCard: React.FC<MatterCardProps> = ({
  reverse,
  className,
  title,
  desc,
  buttonText="体验行业 Demo",
  buttonType="primary",
  buttonHref,
  target = "_blank",
  thumb,
  thumbWidth,
  alt,
  style
}) => {
  
  return (
    <div className={classnames(styles.MatterCard, reverse ? styles.reverse : '', className)} style={style}>
      <div className="matter-card-content">
        <h3>{title}</h3>
        <p>{desc}</p>
        <div className="sd-button-group hidden-mb">
          <Button btnType={buttonType} href={buttonHref} target={target} style={{display: buttonHref ? 'inline-block' : 'none'}}>{buttonText}</Button>
        </div>
      </div>
      <div className="matter-card-thumb">
        <img src={thumb} alt={alt} style={{width: thumbWidth}} />
        <div className="sd-button-group hidden-pc">
          <Button btnType={buttonType} href={buttonHref} target={target} style={{display: buttonHref ? 'inline-block' : 'none'}}>{buttonText}</Button>
        </div>
      </div>
    </div>
  );
}

export default MatterCard;

interface MatterCardGroupProps {
  className?: string;
  dataSource?: MatterCardProps[];
}

export const MatterCardGroup: React.FC<MatterCardGroupProps> = props => {
  const { className, dataSource } = props;
  return (
    <div className={classnames(styles.MatterCardGroup, className)}>
      <div className={styles.MatterCardGroupPC}>
        {
          dataSource?.map(item => <MatterCard key={item.title} {...item} />)
        }
      </div>
      <Swiper 
        className={styles.MatterCardGroupMB}
        pagination={{clickable: true}}
        autoHeight={false}
        allowTouchMove={true}
        autoplay={{
          delay: 6000
        }}
        >
        {
          dataSource?.map(item => <SwiperSlide key={item.title}>
            <MatterCard {...item} />
          </SwiperSlide>)
        }
      </Swiper>
    </div>
  );
}