import React, { useState, useEffect } from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button, { ButtonGroup, ButtonType } from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent, { SectionHeaderMobile } from 'components/SectionContent'
import SolutionsSwiper from 'components/SolutionsSwiper'
import SolutionsSwiperMobile from 'components/SolutionsSwiperMobile'
import { MatterCardGroup } from 'components/MatterCard'
import Scheme from 'components/Scheme'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import PageBottomCard from 'components/PageBottomCard'
import { isPc } from 'utils/utils'

import 'swiper/swiper.min.css'
import * as styles from './index.module.less'

import banner from 'assets/images/features/ABTesting/banner.png'
import Section03_thumb from 'assets/images/features/ABTesting/poster.png'

const ABTesting: React.FC = (props) => {
  return (
    <Layout {...props}>
      <ABTestingState />
    </Layout>
  )
}

export const ABTestingState: React.FC = (props) => {
  const [inPC, setInPC] = useState<boolean>(isPc())
  const demoUrl = '/doMatrixAb?formpage_source2=AB测试'
  const paradeUrl = '/form/parade.html?formpage_source2=AB测试预约演示'

  useEffect(() => {
    window.onresize = () => {
      setInPC(isPc())
    }
  }, [])

  return (
    <>
      <Seo
        title="A/B测试｜贴合业务场景的测试工具-神策数据"
        description="神策数据A/B测试软件，基于全端用户属性、用户行为和行为序列的精准分群及AB试验分组，全景呈现测试对用户旅程及各类关键指标的影响。帮助企业科学决策产品策略，衡量活动运营效果，结合业务场景深入分析下钻寻找增长点，实现持续增长。"
        keywords="AB测试工具,AB测试原理"
        saTitle="首页-产品（AB测试）"
      />
      <main className={styles.Container}>
        <SolutionsBanner
          className="banner-wrap"
          title="A/B  测试"
          subTitle="快测试驱动高增长"
          desc={
            '在分析业务全流程的基础上，有效降低行动成本与风险，帮助产品、运营与营销人员通过测试择优选取策略，实现业绩增长。'
          }
          imgUrl={<img src={banner} alt="" className="banner-icon" />}
          buttonGroup={
            <ButtonGroup>
              <Button href={demoUrl} target="_blank">
                体验 Demo
              </Button>
              <ScanCodeConsultButton
                className={styles.bannerQrcodeButton}
                buttonType={ButtonType.Ghost}
                buttonText="扫码咨询"
                qrcode={require('assets/images/features/ABTesting/wxcode.png').default}
              />
            </ButtonGroup>
          }
        />
        <SolutionsBanner
          className="banner-wrap-mb"
          title="A/B  测试"
          desc="帮助产品、运营与营销人员通过测试择优选取策略，实现业绩增长。"
        />
        <SectionContent className="section-01" title="A/B 测试如何驱动业务增长">
          <SectionHeaderMobile title="A/B 测试如何驱动业务增长" />
          {/* pc端 */}
          <SolutionsSwiper
            className="solutions-swiper-wrap"
            dataSource={[
              {
                className: 'feature-01',
                icon: 'lifecycle',
                tabText: <>产品体验优化</>,
                title: '产品体验优化',
                imgUrl: require('assets/images/features/ABTesting/feature_01.png').default,
                imgWidth: 720,
                descList: [
                  {
                    title: '在产品升级或改版时，帮助您迅速定位更优产品策略',
                  },
                  {
                    title: '衡量页面布局、信息架构、交互方式和功能配置的效果',
                  },
                  {
                    title: '支持新功能的灰度发布，及时管理潜在风险',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-02',
                icon: 'output',
                tabText: <>运营效果提升</>,
                title: '运营效果提升',
                imgUrl: require('assets/images/features/ABTesting/feature_02.png').default,
                imgWidth: 720,
                descList: [
                  {
                    title:
                      '凭借精准的用户分群和高效的用户分流，全景呈现运营策略对用户旅程的影响和相应的关键结果指标，赋能运营动作的 ROI 提升',
                  },
                  {
                    title:
                      '支持通过可视化编辑的方式修改页面元素，无需代码编程即可快速进行文本、图片的替换和样式调整，生成不同版本的试验方案',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-03',
                icon: 'inspect',
                tabText: <>营销效果检验</>,
                title: '营销效果检验',
                imgUrl: require('assets/images/features/ABTesting/feature_03.png').default,
                imgWidth: 720,
                descList: [
                  {
                    title: '面对不同规模的场景和复杂的用户需求，帮助营销人员及时展开对营销内容和推荐算法的测试',
                  },
                  {
                    title:
                      '和神策分析系统所有数据模型进行打通，对受众进行更全面、深入的分析，实现精准定位并衡量营销效果',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
          {/* 移动端 */}
          <SolutionsSwiperMobile
            dataSource={[
              {
                title: '产品体验优化',
                imgUrl: require('assets/images/features/ABTesting/feature_01.png').default,
              },
              {
                title: '运营效果提升',
                imgUrl: require('assets/images/features/ABTesting/feature_02.png').default,
              },
              {
                title: '营销效果检验',
                imgUrl: require('assets/images/features/ABTesting/feature_03.png').default,
              },
            ]}
          />
          {/* 移动端按钮 */}
          <ButtonGroup className="hidden-pc">
            <Button btnType="primary" href={demoUrl} target="_blank">
              体验 Demo
            </Button>
          </ButtonGroup>
        </SectionContent>
        <SectionContent className="section-02" title="A/B 测试实践解读" backgroundColor="#F8FBFD">
          <SectionHeaderMobile title="实战解读" />
          <div className="section-02--content">
            <div
              className="left"
              onClick={() => {
                if (!inPC) window.location.href = 'https://www.sensorsdata.cn/market/playback-detail.html?id=4015'
              }}
            >
              <p className="desc-mb hidden-pc">《科学布局 A/B 测试 助力产品体验、运营效果双提升》</p>
              <div className="thumb">
                <img src={Section03_thumb} alt="科学布局AB测试" />
                <i className="sd-icon video-play"></i>
              </div>
            </div>
            <div className="right hidden-mb">
              <h3>科学布局 A/B 测试 助力产品体验、运营效果双提升</h3>
              <p>聚焦业务运营场景，通过科学布局 A/B 测试，帮助产品、运营与市场同学择优选取策略</p>
              <ul>
                <li>标签：A/B 测试，实战应用，产品体验，精细化运营</li>
                <li className="group">
                  <label>内容亮点：</label>
                  <ul>
                    <li>
                      <i className="sd-icon icon-right"></i> A/B 测试典型应用场景
                    </li>
                    <li>
                      <i className="sd-icon icon-right"></i> A/B 测试赋能业务全流程
                    </li>
                    <li>
                      <i className="sd-icon icon-right"></i> 从设计到实操，A/B 测试实战案例分享
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="sd-button-group">
                <Button
                  btnType="primary"
                  href="https://www.sensorsdata.cn/market/playback-detail.html?id=4015"
                  target="_blank"
                >
                  免费观看
                </Button>
              </div>
            </div>
          </div>
        </SectionContent>
        <SectionContent className="section-03" title="赋能全业务流程的 A/B 测试">
          <SectionHeaderMobile title="赋能全业务流程的 A/B 测试" />
          <MatterCardGroup
            dataSource={[
              {
                title: '精准的用户分群，从源头保证测试准确性',
                desc: '背靠神策数据根基平台，提供基于全端用户属性、用户行为和行为序列的精准分群及试验分组，从源头保证测试的准确性',
                thumb: require('assets/images/features/ABTesting/m-01.png').default,
                alt: '通过AB测试工具创建用户分群',
                thumbWidth: 654,
                buttonText: '体验 Demo',
                buttonHref: demoUrl,
              },
              {
                reverse: true,
                title: '打通神策分析模型，全景呈现测试效果',
                desc: '打通神策分析模型，支持 A/B 测试执行前后的全链路深度下钻分析，结合统计学指标，全景呈现测试对用户旅程及各类关键指标的影响',
                thumb: require('assets/images/features/ABTesting/m-02.png').default,
                alt: '用户行为分析',
                thumbWidth: 654,
                buttonText: '体验 Demo',
                buttonHref: demoUrl,
              },
              {
                title: '高效的用户分流，满足不同场景需求',
                desc: '用户分流同时支持流量正交和流量互斥，高效实现流量复用，适用于不同规模、不同诉求的业务场景',
                thumb: require('assets/images/features/ABTesting/m-03.png').default,
                alt: '用户分层测试',
                thumbWidth: 576,
                buttonText: '体验 Demo',
                buttonHref: demoUrl,
              },
              {
                reverse: true,
                title: '测试能力覆盖各类平台',
                desc: '测试能力覆盖 iOS、Android、微信生态、H5/Web 等各类前端平台，同时支持 Node.js、PHP、Java 等后端开发平台',
                thumb: require('assets/images/features/ABTesting/m-04.png').default,
                alt: 'AB测试前后端覆盖范围',
                thumbWidth: 497,
                buttonText: '体验 Demo',
                buttonHref: demoUrl,
              },
              {
                title: '可靠的技术背书，确保测试稳定性',
                desc: '可靠的多节点分布式部署与多级缓存技术支持，兼容各类底层架构，保证安装部署、测试执行及后续分析的稳定',
                thumb: require('assets/images/features/ABTesting/m-05.png').default,
                alt: '多节点分布式部署与多级缓存技术',
                thumbWidth: 457,
                buttonText: '体验 Demo',
                buttonHref: demoUrl,
              },
            ]}
          />
          <ButtonGroup className="hidden-pc" style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Button btnType="primary" href={demoUrl} target="_blank">
              体验 Demo
            </Button>
          </ButtonGroup>
        </SectionContent>
        <SectionContent className="scheme-wrap" title="为什么选择神策？">
          <SectionHeaderMobile title="为什么选择神策？" />
          <Scheme
            columnNum={4}
            dataSource={[
              {
                title: '产品友好易用',
                desc: '创建测试仅需四步，并有丰富场景库支撑',
                icon: require('assets/images/features/ABTesting/t-01.svg').default,
                alt: 'icon',
              },
              {
                title: '报告实时生成',
                desc: '一键生成实时的专业测试报告，保证时效性与决策效率',
                icon: require('assets/images/features/ABTesting/t-02.svg').default,
                alt: 'icon',
              },
              {
                title: '更多试验类型',
                desc: '支持推荐算法试验、多链接试验和可视化试验',
                icon: require('assets/images/features/ABTesting/t-03.svg').default,
                alt: 'icon',
              },
              {
                title: '支持全端部署',
                desc: '同时支持 SaaS 版本和私有化部署，满足各行业需求',
                icon: require('assets/images/features/ABTesting/t-04.svg').default,
                alt: 'icon',
              },
            ]}
          />
          <div className="sd-button-group">
            <ScanCodeConsultButton
              buttonText="扫码咨询"
              qrcode={require('assets/images/features/ABTesting/wxcode.png').default}
            />
          </div>
        </SectionContent>
        <PageBottomCard
          title="高增长，从 A/B 测试开始！"
          desc="立即体验，通过低成本、低风险、高收益的 A/B 测试驱动业务增长！"
          leftButtonText="体验 Demo"
          leftButtonHref={demoUrl}
          rightButtonText="预约演示"
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  )
}

export default ABTesting
